import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState('relative');
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('bg-black') : setStickyClass('bg-transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg transition-all ${stickyClass}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              homeNav();
            }}
          >
            <span className="iconify" data-icon="humbleicons:bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-md-none d-block text-end p-4">
              <a href="javascript:void(0)" onClick={() => {homeNav();}}  className="cross-btn text-white">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav mx-auto align-items-center px-4 px-lg-0">
              {/* <li className="nav-item">
                <a href="javascript:void(0)" className="nav-link" onClick={() => {homeNav()}}>
                RoadMap
                </a>
              </li> */}
              
            </ul>
            <div className="d-lg-flex gap-3 px-4 px-lg-0 mt-lg-0 mt-4">
            <div className="d-flex gap-3">
            <a href="https://discord.gg/8wPCFbyQPg" target={"_blank"} className="nav-ic">
            <div className="icon"><span className="iconify" data-icon="ic:baseline-discord"></span></div>
                </a>
                <a href="https://twitter.com/drop_dex" target={"_blank"} className="nav-ic">
            <div className="icon"><span className="iconify" data-icon="mdi:twitter"></span></div>
                </a>
                <a href="https://medium.com/@dropdex" target={"_blank"} className="nav-ic">
            <div className="icon"><span className="iconify" data-icon="mingcute:medium-fill"></span></div>
                </a>
            </div>
                <a href="http://docs.dropdex.xyz/" target={"_blank"} className="primary-btn-outline h-40">
                  <span>Documentation</span>
                </a>
              <a href="http://app.dropdex.xyz/" target={"_blank"} className="primary-btn d-inline-flex mt-lg-0 mt-4">
                Enter App
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
