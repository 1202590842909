import React from "react";

const Home = () => {
  return (
    <>
      {/* Banner  */}
      <section className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-10 z-1">
              <h1>
              Swap, Earn, <br  /> Bridge
              </h1>
              <p className="my-4">
              One-Click Exchange and Liquidity Marketplace <br className="d-lg-block d-none" /> on SUI Network
              </p>
              <div className="">
                <a href="javascript:void(0)" className="primary-btn-outline">
                  <span>Learn More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section className="how-it-work">
        <div className="container">
          <div className="row justify-content-center align-items-center py-4 py-lg-5">
            <div className="col-md-6">
              <h2>DropDex Earn</h2>
              <p>Use our Pools, Farms, and Liquidity tools to generate yield and earn passive income with your crypto assets</p>
            </div>
            <div className="col-md-6">
            <img src="assets/h1.png" className="w-100" alt="" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center py-4 py-lg-5">
          <div className="col-md-6 order-md-0 order-1">
            <img src="assets/h2.png" className="w-100" alt="" />
            </div>
            <div className="col-md-6">
              <h2>DropDex Swap</h2>
              <p>Swap and exchange your crypto assets with our swap tool with rich selection of tokens from Sui Ecosystem</p>
            </div>
            
          </div>

          <div className="row justify-content-center align-items-center py-4 py-lg-5">
            <div className="col-md-6">
              <h2>DropDex Bridge</h2>
              <p>Our bridge tool help you to reach all major networks! Instant, Fast, Secure, and Cheap.</p>
            </div>
            <div className="col-md-6">
            <img src="assets/h3.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </section>


    {/* <section className="roadmap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <h2>ROADMAP</h2>

                    <div className="roadmap-card">
                      <div className="map-bg">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="map-dot"></div>
                            <h5>Stage I</h5>
                            <p>DropDex Public Testnet Launch</p>
                          </div>
                          <div className="col-md-3">
                            <div className="map-dot"></div>
                            <h5>Stage II</h5>
                            <p>DropDex TGE and DEX Listing</p>
                          </div>
                          <div className="col-md-3">
                            <div className="map-dot"></div>
                            <h5>Stage III</h5>
                            <p>V1 Mainnet Launch</p>
                          </div>
                          <div className="col-md-3">
                            <div className="map-dot last"></div>
                            <h5>Stage IV</h5>
                            <p>Governance Launch</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
    </section> */}


      <section className="feature">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-11 text-center">
            <h2>Built on top of</h2>
            <img src="assets/sui.svg" alt="" />
          </div>
        </div>
       
      </div>
      </section>


     
      
    </>
  );
};

export default Home;
