import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-2">
              <div className="footer-logo">
              <Link to="/">
            <img src="assets/logo.svg" height={30} alt="" />
          </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex gap-md-5 gap-4 justify-content-md-end justify-content-center mb-md-0 mb-3">
                <a href="javascript:void()" className='footer-link'>Terms of Service</a>
                <a href="javascript:void()" className='footer-link'>Privacy Policy</a>
                <a href="javascript:void()" className='footer-link'>Media Kit</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer